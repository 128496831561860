<template>
  <v-container fluid>
    <v-row>
      <v-col md="6" cols="12">
        <div class="conOfHeader">
          <div class="headerOfPage">Permissions</div>
        </div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="conOfHeader text-right">
          <div class="btnAdd">
            <v-btn class="btnAddPrimary mr-3" @click="dialogProject = true">
              <v-icon>mdi-plus</v-icon>
              add New Permissions
            </v-btn>
            <!--    <v-btn class="btnAddPrimary mr-3" @click="departmentDialoge = true">
              <v-icon>mdi-plus</v-icon>
              Create New department
            </v-btn> -->
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row v-if="listData && listData.length > 0">
      <v-col cols="12">
        <section class="conOfProjects">
          <div class="conOfTable">
            <v-data-table
              :headers="headers"
              :items="listData"
              :items-per-page="10"
              hide-default-footer
            >
              <template v-slot:[`item.users`]="{ item }">
                <div v-for="user in item.users" :key="user.name">
                  {{ user.name }}
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-menu bottom :left="true" :offset-y="true">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="openDialogForEdit(item)">
                      <v-list-item-title class="statusTxt">
                        Edit Users Permissions
                      </v-list-item-title>
                    </v-list-item>
                    <!--      <v-list-item @click="openDepartmentDialogForEdit(item)">
                      <v-list-item-title class="statusTxt">
                        Edit Departments
                      </v-list-item-title> 
                    </v-list-item> -->
                  </v-list>
                </v-menu>
              </template>
            </v-data-table>
          </div>
        </section>
        <div
          class="conOfPagination pr-5 pt-5"
          v-if="pagination.total > pagination.per_page"
        >
          <v-pagination
            :total-visible="7"
            v-model="pagination.current_page"
            :length="pagination.last_page"
            @input="goToPage(pagination.current_page)"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <v-row v-else>
      <v-col cols="12" class="mt-7">
        <EmptyState />
      </v-col>
    </v-row>
    <v-snackbar
      v-model="successSnackbar"
      color="success"
      shaped
      absolute
      top
      right
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      shaped
      top
      right
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
    <v-dialog scrollable v-model="dialogProject" max-width="700" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">{{
            selectedDialogTitle
          }}</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Permission Name</label>
                <v-row>
                  <v-col
                    v-for="(item, index) in listData"
                    :key="index"
                    cols="4"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        v-model="userPerForm.permission_ids"
                        :value="item.id"
                      ></v-checkbox>
                      {{ item.name }}
                    </div>
                  </v-col>
                </v-row>
                <v-pagination
                  :total-visible="7"
                  v-model="pagination.current_page"
                  :length="pagination.last_page"
                  @input="goToPage(pagination.current_page)"
                ></v-pagination>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel"
                  >Select Name to Assign Permission</label
                >

                <v-row>
                  <v-col
                    v-for="(item, index) in userListData"
                    :key="index"
                    cols="4"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        v-model="userPerForm.user_ids"
                        :value="item.id"
                      ></v-checkbox>
                      {{ item.name }}
                    </div>
                  </v-col>
                </v-row>
                <v-pagination
                  :total-visible="7"
                  v-model="userPagination.current_page"
                  :length="userPagination.last_page"
                  @input="goToUserPage(userPagination.current_page)"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeProjectModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="assignUserPer"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--   <v-dialog scrollable v-model="departmentDialoge" max-width="500" persistent>
      <v-card class="conOfPopup">
        <v-card-title>
          <span class="text-h5 callbacks modalTitle">{{
            selectedDepartmentTitle
          }}</span>
        </v-card-title>

        <v-card-text class="conFormSubmit scrollbar">
          <v-container>
            <v-row>
              <v-col cols="12">
                <label class="eachLabel">Permission Name</label>
                <v-row>
                  <v-col
                    v-for="(item, index) in listData"
                    :key="index"
                    cols="4"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        v-model="deparmentPerForm.permission_ids"
                        :value="item.id"
                      ></v-checkbox>
                      {{ item.name }}
                    </div>
                  </v-col>
                </v-row>
                <v-pagination
                  :total-visible="7"
                  v-model="pagination.current_page"
                  :length="pagination.last_page"
                  @input="goToPage(pagination.current_page)"
                ></v-pagination>
              </v-col>
              <v-col cols="12">
                <label class="eachLabel"
                  >Select Department to Assign Permission</label
                >

                <v-row>
                  <v-col
                    v-for="(item, index) in deparmentListData"
                    :key="index"
                    cols="4"
                  >
                    <div class="d-flex align-center">
                      <v-checkbox
                        v-model="deparmentPerForm.department_id"
                        :value="item.id"
                      ></v-checkbox>
                      {{ item.name_en }}
                    </div>
                  </v-col>
                </v-row>
                <v-pagination
                  :total-visible="7"
                  v-model="departmentPagination.current_page"
                  :length="departmentPagination.last_page"
                  @input="goToDepPage(departmentPagination.current_page)"
                ></v-pagination>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="pb-10">
          <v-spacer></v-spacer>
          <v-btn class="btnCancel" tile @click="closeDeparmentModal">
            Cancel
          </v-btn>
          <v-btn
            class="btnPrimaryOrg"
            @click="assignDepartmentPer"
            :disabled="isLoadingSave"
            :loading="isLoadingSave"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </v-container>
</template>

<script>
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
import EmptyState from "@/modules/shared/components/emptystate";
import { mapGetters } from "vuex";
export default {
  data: () => ({
    headers: [
      {
        text: "NAME",
        align: "start",
        value: "name",
        sortable: false,
      },
      { text: "Users", value: "users" },
      { text: "", value: "actions", sortable: false, align: "right" },
    ],
    successSnackbar: false,
    errorSnackbar: false,
    timeout: 3000,
    searchQuery: "",
    dialogProject: false,
    departmentDialoge: false,
    isLoading: false,
    pagination: {
      current_page: 1,
    },
    userPagination: {
      current_page: 1,
    },
    departmentPagination: {
      current_page: 1,
    },
    users: {},
    departments: {},
    userPerForm: {
      permission_ids: [],
      user_ids: [],
    },
    deparmentPerForm: {
      permission_ids: [],
      department_id: "",
    },
    selectedItem: null,
    listData: [],
    userListData: [],
    deparmentListData: [],
    selectedItemForEdit: {},
    editedIndex: -1,
    editedDepIndex: -1,
  }),
  computed: {
    ...mapGetters(["apiMessage"]),
    selectedDialogTitle() {
      return this.editedIndex === -1 ? "Add New Permission" : "Edit Permission";
    },
    selectedDepartmentTitle() {
      return this.editedDepIndex === -1
        ? "Add New Department Permission"
        : "Edit Deparment Permission";
    },
  },
  components: { EmptyState },
  methods: {
    assignUserPer() {
      this.isLoadingSave = true;
      apiServices
        .post("grant-revoke-user-permission", this.userPerForm)
        .then((res) => {
          if (res) {
            this.isLoadingSave = false;
            this.getListData();
            this.userPerForm = "";
            this.dialogProject = false;
          } else {
            this.isLoadingSave = false;
            let errors = Object.values(this.apiMessage)[0];
            errors.forEach((err) => {
              this.errorMessage = err;
              console.log("errorMessage", err);
            });
            this.errorSnackbar = true;
          }
        });
    },
    assignDepartmentPer() {
      this.isLoadingSave = true;
      apiServices
        .post("grant-revoke-department-permission", this.deparmentPerForm)
        .then((res) => {
          if (res) {
            this.isLoadingSave = false;
            this.getListData();
            this.deparmentPerForm = "";
            this.departmentDialoge = false;
          } else {
            this.isLoadingSave = false;
            let errors = Object.values(this.apiMessage)[0];
            errors.forEach((err) => {
              this.errorMessage = err;
              console.log("errorMessage", err);
            });
            this.errorSnackbar = true;
          }
        });
    },

    goToPage(page) {
      this.getListData(page);
      window.scrollTo(0, 0);
    },
    goToUserPage(page) {
      this.getUserListData(page);
      window.scrollTo(0, 0);
    },
    goToDepPage(page) {
      this.getDepartmentListData(page);
      window.scrollTo(0, 0);
    },
    getListData(page) {
      this.listData = [];
      this.isLoading = true;
      const query = this.searchQuery
        ? `&search=${encodeURIComponent(this.searchQuery)}`
        : "";

      apiServices
        .get(`permissions?per_page=7&page=${page}${query}`)
        .then((res) => {
          if (res) {
            this.listData = res.data;
            this.pagination = res.meta;
            this.isLoading = false;
          } else this.isLoading = false;
        });
    },
    searchUsers() {
      this.pagination.current_page = 1;
      this.getListData(this.pagination.current_page);
    },
    getUserListData(page) {
      this.userListData = [];
      this.isLoading = true;

      apiServices.get(`users?per_page=7&page=${page}`).then((res) => {
        if (res) {
          this.userListData = res.data;
          this.userPagination = res.meta;
          this.isLoading = false;
        } else this.isLoading = false;
      });
    },
    // getDepartmentListData(page) {
    //   this.deparmentListData = [];
    //   this.isLoading = true;

    //   apiServices.get(`departments?per_page=7&page=${page}`).then((res) => {
    //     if (res) {
    //       this.deparmentListData = res.data;
    //       console.log("deparmentListData", this.deparmentListData);
    //       this.departmentPagination = res.meta;
    //       this.isLoading = false;
    //     } else this.isLoading = false;
    //   });
    // },
    openDialogForEdit(item) {
      this.editedIndex = item.id;
      let userIDs = [];
      if (item.users && item.users.length > 0) {
        userIDs = item.users.map((user) => user.id);
      }

      let editFormItem = {
        user_ids: userIDs,
        permission_ids: [item.id],
      };
      this.userPerForm = editFormItem;
      this.dialogProject = true;
    },
    openDepartmentDialogForEdit(item) {
      this.editedDepIndex = item.id;
      console.log("");
      let departmentIDs = [];
      if (item.departments && item.departments.length > 0) {
        departmentIDs = item.departments.map((department) => department.id);
      }

      let editFormItem = {
        department_id: departmentIDs,
        permission_ids: [item.id],
      };
      this.deparmentPerForm = editFormItem;
      this.departmentDialoge = true;
    },
    closeProjectModal() {
      this.selectedItem = null;
      this.dialogProject = false;
    },
    closeDeparmentModal() {
      this.selectedItem = null;
      this.departmentDialoge = false;
    },
  },

  created() {
    this.getListData(this.pagination.current_page);
    this.getUserListData(this.userPagination.current_page);
    // this.getDepartmentListData(this.departmentPagination.current_page);
  },
  watch: {},
};
</script>
<style lang="scss" scoped></style>
